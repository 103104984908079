<template>
  <WebHeader />
  <div class="g-container">
    <div class="sphere_title">Rotating Sphere</div>

    <div class="sphere_container">
      <img class="sphere_img" src="https://wz.xzaizl.cloud/indexImages/animation.gif" alt=""
        @click="clickImge(item.link)">
    </div>
    <WebFooter />
  </div>
</template>
<script>
import WebHeader from '@/components/WebHeader.vue'
import WebFooter from '@/components/WebFooter.vue'


export default {
  name: 'RotatingSphere',
  components: {
    WebFooter,
    WebHeader
  },
}
</script>
<style scoped lang="scss">
.sphere_title {
  font-size: 24px;
  font-weight: bold;
  margin-top: 100px;
  text-align: center;
}

.sphere_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .sphere_img {
    width: 70%;
  }
}</style>
