<template>
  <div class="video-container">
    <video :id="'video-' + id" class="video-js video" controls></video>
    <div class="video-title">{{ videoTitle }}</div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'

export default {
  name: 'VideoPLayer',
  props: {
    id: {
      type: Number,
      required: true,
    },
    videoAddress: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const videoTitle = ref(props.videoAddress.split('-')[0].split('/')[3])

    const setupVideoListeners = () => {
      const video = document.getElementById('video-' + props.id)
      const player = videojs(video, {playbackRates: [0.5, 1, 1.5, 2]})
      player.src({ src: props.videoAddress, type: 'application/x-mpegURL' })
      const videos = document.querySelectorAll('video')
      videos.forEach(() => {
        player.on('play', () => {
          videos.forEach((otherVideo) => {
            const otherPlayer = videojs(otherVideo)
            if (otherPlayer !== player) {
              otherPlayer.pause()
              otherPlayer.currentTime(0)
              otherPlayer.playbackRate(1)
            }
          })
        })

        player.on('fullscreenchange', () => {
          if (player.isFullscreen()) {
            videos.forEach((otherVideo) => {
              const otherPlayer = videojs(otherVideo)
              if (otherPlayer !== player) {
                otherPlayer.pause()
                otherPlayer.currentTime(0)
                otherPlayer.playbackRate(1)
              }
            })
            player.play().catch(error => {
              console.error('播放失败:', error)
            })
          }
        })
      })
    }

    onMounted(() => {
      setupVideoListeners()
    })

    return {
      videoTitle,
    }
  },
}
</script>
<style scoped lang="scss">
.video-container {
  .video-title {
    font-size: 20px;
    font-weight: bold;
    padding-top: 20px;
  }

  .video {
    width: 390px;
    height: 240px;
    padding: 8px;
  }

  :deep(.video-js){
    margin: 8px;
    .vjs-big-play-button {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  }

  :deep(.vjs-paused .vjs-big-play-button){
    display: block !important;
    opacity: 1 !important;
    visibility: visible !important;
  }
}
</style>
