<template>
  <WebHeader/>
  <div class="g-container">
    <div class="article-author">Author: Sam Altman</div>
    <div class="fav-article">
      <div v-html="article_html"/>
    </div>
    <WebFooter/>
  </div>
</template>
<script>
import {ref, onMounted} from 'vue'
import WebHeader from '@/components/WebHeader.vue'
import WebFooter from '@/components/WebFooter.vue'
import articleApi from '@/api/article'
import {useRouter} from 'vue-router'

export default {
  name: 'FavArticle',
  props: {
    articleId: String
  },
  components: {
    WebHeader,
    WebFooter
  },
  setup() {
    const article_html = ref('');
    const article_title = ref('');
    const router = useRouter()

    const load = () => {
      articleApi.getFavArticleInfor('xiaozhi').then((res) => {
        if (res['resCode'] === 200) {
          res = res['resMsg']['data']
          article_title.value = res[0].article_name,
          article_html.value = res[0].article_html
        } else {
          router.push({name: 'UserLogin', params: {name: 'FavoriteArticle'}})
        }
      })
    }
    onMounted(() => {
      load()
    })
    return {
      article_html,
      article_title
    };
  },
}
</script>
<style scoped lang="scss">
.article-author {
  font-size: 30px;
  font-weight: bolder;
}
.fav-article {
  color: #fff;
  font-size: 24px;
  text-align: left;
  background-color: rgba(255,255,255,0);
  margin: 20px 200px;

  :deep(a) {
    color: #2c3e50;
  }
}
</style>
