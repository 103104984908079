<template>
  <WebHeader/>
  <div class="g-container">
    <div class="ai-articles">
      <div v-for="aiArticle in aiArticles" :key="aiArticle.article_id">
        <div class="ai-article" v-if="aiArticle.article_id">
          <img class="ai-article-img" :src="aiArticle.article_img" alt="" @click="clickImge(aiArticle.article_id)">
          <div class="ai-article-text">{{ aiArticle.article_name }}</div>
        </div>
      </div>
    </div>
    <a-pagination @change="onChange" v-model:current="current" v-model:page-size="pageSizeRef"
                  :page-size-options="pageSizeOptions"
                  :total="total" show-size-changer @showSizeChange="onShowSizeChange">
      <template #buildOptionText="props">
        <span v-if="props.value !== total">{{ props.value }}条/页</span>
      </template>
    </a-pagination>
    <WebFooter/>
  </div>
</template>
<script>
import WebHeader from '@/components/WebHeader.vue'
import WebFooter from '@/components/WebFooter.vue'
import {ref, onMounted} from 'vue'
import articleApi from '@/api/article'
import {useRouter} from 'vue-router'
import {Pagination} from 'ant-design-vue'


export default {
  name: 'AiArticles',
  components: {
    WebFooter,
    WebHeader,
    APagination: Pagination
  },
  props: {},
  setup() {
    const aiArticles = ref('')
    const pageSizeOptions = ref(['9', '18', '27', '36'])
    const total = ref(0)
    const current = ref(1)
    const pageSizeRef = ref(9)
    const onShowSizeChange = (current, pageSize) => {
      pageSizeRef.value = pageSize
    }
    const onChange = (page, pageSize) => {
      current.value = page
      pageSizeRef.value = pageSize
      load()
    }
    const load = () => {
      articleApi.getAiArticlesInfor(current.value, pageSizeRef.value).then((res) => {
        if (res['resCode'] === 200) {
          res = res['resMsg']['data']
          aiArticles.value = res.ai_articles
          total.value = res.num_pages * pageSizeRef.value
        } else {
          router.push({name: 'UserLogin', params: {name: 'AiArticles'}})
        }
      })
    }
    const router = useRouter()

    const clickImge = (article_id) => {
      router.push({name: 'AiArticle', params: {articleId: article_id}})
    }
    onMounted(() => {
      load()
    })
    return {
      aiArticles,
      clickImge,
      pageSizeOptions,
      current,
      pageSizeRef,
      total,
      onShowSizeChange,
      onChange
    }
  }
}
</script>
<style scoped lang="scss">
.ai-articles {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;

  .ai-article {
    width: 405px;
    height: 260px;
    margin: 20px 0 20px 33px;
    padding-bottom: 40px;
  }

  .ai-article-img {
    background-color: rgba(255,255,255,0);
    border-radius: 5px;
    width: 365px;
    height: 240px;
  }

  .ai-article-text {
    padding: 20px;
    font-size: 20px;
    font-weight: bold;
  }
}
</style>
