<template>
  <WebHeader />
  <div class="g-container">
    <div class="write-article">
      <div class="title">记录你的成长</div>
      <div class="lable">
        <div>编辑</div>
        <div>预览</div>
      </div>
      <ArticleEditor @submit="submit"></ArticleEditor>
    </div>
    <WebFooter />
  </div>
</template>

<script>
import ArticleEditor from '@/components/ArticleEditor.vue'
import WebHeader from '@/components/WebHeader.vue'
import WebFooter from '@/components/WebFooter.vue';
import articleApi from '@/api/article'
import { useRouter } from 'vue-router'
export default {
  name: 'EditArticle',
  components: {
    ArticleEditor,
    WebFooter,
    WebHeader
  },

  setup() {
    const router = useRouter();
    const submit = (content) => {
      const { v1: uuidv1 } = require('uuid');
      const article_id = uuidv1();
      const data = {
        article_id: article_id,
        article_name: content.title,
        article_input: content.input,
        article_html: content.html
      }
      articleApi.updateMyArticle(data).then(() => {
        router.push({ name: 'MyArticle', params: { articleId: article_id } })
      })
    }
    return {
      submit
    }
  }
}
</script>
<style scoped lang="scss">
.write-article {
  margin: 20px;
  color: #fff;
}
.title {
  font-size: 30px;
  font-weight: bolder;
  padding-top: 20px;
}
.lable {
  display: flex;
  justify-content: space-around;
  padding-top: 20px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom:  20px;
}
</style>
