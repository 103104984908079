<template>
  <div>
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style scss="scss">
#app {
  min-height: 100vh;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-image: url("./assets/heike.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
  margin-top: 20px;
}
.g-container {
  margin: auto;
  padding-top: 16px;
  width: 1350px;
  background-color: rgba(255,255,255,0);
  border-radius: 5px;
}
</style>
