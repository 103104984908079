<template>
  <WebHeader />
  <div class="g-container">
    <div class="clastify">
      <div class="clastify_container">
        <div v-for="(item, index) in clastifys" :key="index">
          <img :src=item.image alt="" @click="clickImge(item.link)">
          <div class="clastify_text">{{ item.text }}</div>
        </div>
      </div>
    </div>
    <WebFooter />
  </div>
</template>

<script>
import WebHeader from '@/components/WebHeader.vue'
import WebFooter from '@/components/WebFooter.vue'
import { useRouter } from 'vue-router'
import loginApi from '@/api/login'
import {onMounted} from "vue";

export default {
  name: 'IndexPage',
  components: {
    WebFooter,
    WebHeader
  },
  setup() {
    const clastifys = [
    {
        image: "https://wz.xzaizl.cloud/indexImages/my_articles.png",
        text: 'Articles written by I',
        link: '/my_articles'
      },
      {
        image: "https://wz.xzaizl.cloud/indexImages/ai_articles.png",
        text: 'Articles written by AI',
        link: '/ai_articles'
      },
      {
        image: "https://wz.xzaizl.cloud/indexImages/successful.png",
        text: 'Favorite Article',
        link: '/favorite_article'
      },
      {
        image: "https://wz.xzaizl.cloud/indexImages/chatgpt.png",
        text: 'ChatGPT',
        link: 'https://xzaizl.cloud/chatgpt'
      },
      {
        image: "https://wz.xzaizl.cloud/indexImages/myvideo.png",
        text: 'GameVideos',
        link: '/videos'
      },
      {
        image: "https://wz.xzaizl.cloud/indexImages/animation.gif",
        text: 'Rotating Sphere',
        link: '/rotating_sphere'
      },
      
    ]
    const router = useRouter()
    const load = () => {
      loginApi.checkLogin().then((res) => {
        if (res['resMsg']['status']) {
          router.push({name: 'Index'})
        } else {
          router.push({name: 'UserLogin', params: {name: 'Index'}})
        }
      })
    }
    onMounted(() => {
      load()
    })
    const clickImge = (link) => {
      const pattern = /^https:\/\//
      if (pattern.test(link)) {
        window.location.href = link
      } else {
        router.push(link)
      }
    }
    return {
      clastifys,
      clickImge,
      load
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
.clastify {
  height: 100vh;
}

.clastify_container {
  display: flex;
  flex-wrap: wrap;

  img {
    background-color: rgba(255,255,255,0);
    border-radius: 5px;
    margin: 20px 0 10px 33px;
    padding: 20px;
    width: 365px;
    height: 240px;
  }

  .clastify_text {
    font-size: 20px;
    font-weight: bold;
    margin: 20px 0 10px 33px;
  }
}</style>
